<template>
    <footer class="css-88z093">
        <footer class="css-1h9xuna">
            <section class="css-1qzhyb0">
                <ul class="css-zkzuyb">
                    <div class="css-t0ptnf">
                        <li class="css-516vln"><a target="_blank"
                                class="css-y8zw8d">개인정보처리방침</a></li>
                        <li class="css-516vln"><a target="_blank"
                                class="css-1vh48ao">서비스
                                이용약관</a></li>
                    </div>
                </ul>
            </section>
            <div class="css-k50gal">
                <section class="css-0">
                    <h4 class="css-16omddv">(주)BOOTSHELF 사업자 정보<!-- -->&nbsp;</h4>
                    <dl class="css-hdat01">
                        <div class="css-5znsbo">
                            <dt class="css-184cnz3">대표자 :</dt>
                            <dd class="css-184cnz3">BuildUp</dd><span class="css-17y1dcm"> | </span>
                        </div>
                        <div class="css-5znsbo">
                            <dt class="css-184cnz3">사업자 등록번호 :</dt>
                            <dd class="css-184cnz3">000-000-0000</dd><span class="css-17y1dcm"> | </span>
                        </div>
                        <div class="css-5znsbo">
                            <dt class="css-184cnz3">통신판매업 신고번호 :</dt>
                            <dd class="css-184cnz3">0000-서울동작-00000</dd><span class="css-17y1dcm"> | </span>
                        </div>
                        <div class="css-5znsbo"><a target="_blank"
                                class="css-v0a2uo">평생교육시설 신고번호: 제 000호</a></div>
                        <div class="css-5znsbo">
                            <dt class="css-184cnz3">주소 :</dt>
                            <dd class="css-184cnz3">서울특별시 동작구 보라매로 87 신이빌딩 402호</dd><span class="css-17y1dcm"> |
                            </span>
                        </div>
                        <div class="css-5znsbo">
                            <dt class="css-184cnz3">이메일 :</dt>
                            <dd class="css-184cnz3">bootshelf@ohyeah.com</dd><span class="css-17y1dcm"> |
                            </span>
                        </div>
                        <div class="css-5znsbo">
                            <dt class="css-184cnz3">전화 : </dt>
                            <dd class="css-184cnz3">0000-0000</dd>
                        </div>
                    </dl>
                </section>
                <p class="css-4mb2gv">Copyright ©2024 BOOKSHELF. All rights reserved.</p>
            </div>
        </footer>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style scoped>
.css-88z093 {
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 1230;
    border-top: 1px solid #E4EBF0;
}

.css-88z093 * {
    font-family: Pretendard, -apple-system, “system-ui”, "Malgun Gothic", "맑은 고딕", sans-serif;
    line-height: 1.5;
}

.css-1h9xuna {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 1100px;
    width: 100%;
    margin: 40px auto 100px;
    padding: 0 24px;
}

.css-vn5ffn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.css-1ccrqs4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}


.css-14cspio {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #141617;
    color: #5F666B;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 4px;
}

.css-tgyf2d {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 8px 0;
    gap: 6px;
}


.css-w6ze0h {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #141617;
    color: #81898F;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
}

.css-b54cxe {
    padding: 4px;
    cursor: pointer;
}

.css-n0efe5 {
    width: 6px;
}

.css-tkvg23 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 1px 4px;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #FFF1F4;
    color: #FF6C7A;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.css-ajaiss {
    height: 1px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 100%;
    background: #E4EBF0;
    margin: 24px 0;
}

.css-1qzhyb0 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.css-zkzuyb {
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    color: #d4d4d4;
    margin-bottom: 12px;
}

.css-t0ptnf {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.css-516vln {
    padding: 0;
    margin-right: 24px;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.css-516vln a {
    font-size: 12px;
}

.css-y8zw8d {
    color: #5F666B;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    white-space: nowrap;
}

.css-1vh48ao {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #141617;
    color: #81898F;
    width: 100%;
    white-space: nowrap;
}

.css-k50gal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}


.css-16omddv {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #141617;
    height: 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    color: #9DA7AE;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    white-space: nowrap;
}

.css-hdat01 {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #141617;
    font-size: 12px;
    color: #9DA7AE;
    display: none;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 4px;
}

.css-5znsbo {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.css-184cnz3 {
    line-height: 20px;
    padding-left: 0px;
    margin-left: 0px;
}

.css-17y1dcm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    line-height: 20px;
}

.css-v0a2uo {
    font-size: 12px;
    line-height: 20px;
}

.css-4mb2gv {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #141617;
    font-size: 12px;
    color: #9DA7AE;
    padding-top: 16px;
}


@media(min-width: 320px) and (max-width: 819px) {
    .css-zkzuyb {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 24px;
    }

    .css-w6ze0h {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 6px;
    }

    .css-tgyf2d {
        padding: 16px 12px;
        margin: 0;
    }

    .css-1h9xuna {
        margin: 16px auto 100px;
        padding: 0 16px;
    }

    .css-ajaiss {
        height: 0;
    }

    .css-1qzhyb0 {
        gap: 24px;
    }

    .css-1ccrqs4 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 16px 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid #E4EBF0;
    }

    .css-b54cxe {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 4px;
    }
}

@media (min-width: 820px) {
    .css-hdat01 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 700px;
    }

    .css-zkzuyb {
        margin-bottom: 0;
        font-size: 14px;
    }
}

@media (min-width: 820px) and (max-width: 1023px) {
    .css-1h9xuna {
        margin: 40px auto 100px;
    }

    .css-vn5ffn {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(4, 1fr);
    }

    .css-vn5ffn>:first-child {
        grid-row: 1/span 1;
        grid-column: 1/span 4;
    }

    .css-vn5ffn>:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
    }

    .css-vn5ffn>:nth-child(3) {
        grid-row: 2;
        grid-column: 2;
    }

    .css-vn5ffn>:nth-child(4) {
        grid-row: 2;
        grid-column: 3;
    }

    .css-vn5ffn>:nth-child(5) {
        grid-row: 2;
        grid-column: 4;
    }
}

@media(min-width: 1024px) {
    .css-1h9xuna {
        margin: 32px auto 100px;
    }

    .css-4mb2gv {
        text-align: right;
    }

    .css-5znsbo {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .css-k50gal {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
    }

    .css-vn5ffn {
        display: grid;
        gap: 24px;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }
}
</style>